//get the number of products per row based on the current window width
function getResponsiveProductGridRowLength(carousel) {
    if (carousel) {
        switch(true) {
            case $(window).width() >= 1200:
                return 5;
            case $(window).width() >= 992:
                return 4;
            case $(window).width() >= 768:
                return 3;
            default:
                return 2;
        }
    } else {
        switch(true) {
            case $(window).width() >= 1200:
                return 4;
            case $(window).width() >= 992:
                return 3;
            default:
                return 2;
        }
    }
}

export {getResponsiveProductGridRowLength}
//remove right/bottom product-item borders based on number of products per row/within last row
import {getResponsiveProductGridRowLength} from "./getResponsiveProductGridRowLength";

function productsGridResponsiveBorders(products_grid, carousel, product_tables) {
    var products_per_row = getResponsiveProductGridRowLength(carousel);
    var nth_child_selector = ':nth-child(' + products_per_row + 'n)';
    var product_number_in_last_row = products_grid.children().length % products_per_row;
    var last_product = products_grid.children().last();
    var previous_product = last_product.prev();

    if (product_number_in_last_row == 0) {
        product_number_in_last_row = products_per_row;
    }

    if (carousel) {
        products_grid.find('.slick-track .item.slick-active:last').children().css('border-right', 'none');
        products_grid.find('.slick-track .item.slick-active').children().css('border-bottom', 'none');

        if (products_grid.children('.item').length == 1) {
            products_grid.find('.product-tile').css('border-right', 'none');
        }
    } else if (!product_tables) {
        products_grid.children().css('border-left', 'none');
        last_product.css('border-bottom', 'none');
        products_grid.find(nth_child_selector).css('border-right', 'none');

        if (products_grid.children().length == 1) {
            products_grid.children().first().css('border-bottom', 'none').css('border-right', 'none');
        }

        if (product_number_in_last_row == 1) {
            last_product.css('border-left', '1px solid #e8e7ea');
        }

        for (var i = 1; i < product_number_in_last_row; i++) {
            previous_product.css('border-bottom', 'none');
            if (i == product_number_in_last_row - 1 &&
                product_number_in_last_row != products_per_row) {
                previous_product.css('border-left', '1px solid #e8e7ea')
            }

            previous_product = previous_product.prev();
        }
    }
}

export {productsGridResponsiveBorders}
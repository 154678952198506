import "../../../scss/components/product/_products-grid.scss"

import {productsGridResponsiveBorders} from "../../app/functions/productsGridResponsiveBorders";

$(document).ready(function() {
    $('.products-grid').each(function() {
        var products_grid = $(this);
        var carousel = products_grid.hasClass('multi-item-carousel');
        var product_tables = products_grid.hasClass('product-tables');

        productsGridResponsiveBorders(products_grid, carousel, product_tables);

        $(window).resize(function() {
            products_grid.find('.product-item').each(function() {
                if ($(this).hasClass('grid-view')) {
                    $(this).css('border-right', '1px solid #e8e7ea');
                }

                if (!carousel) {
                    $(this).css('border-bottom', '1px solid #e8e7ea');
                }
            })

            productsGridResponsiveBorders(products_grid, carousel, product_tables);
        })
    })
})